import React from "react";
import { useNavigate } from "react-router-dom";
import { Image, PrimaryButton, Text } from "@fluentui/react";
import { track } from "segment";
import { IconList } from "taskpane/components";
import { acceptWelcome } from "utils/localStorage";

import styles from "./Welcome.module.scss";

/**
 * When the user clicks on the Start button of the Welcome screen.
 */
const trackWelcomeScreen = (): void => {
  track("Welcome Screen");
};

export const Welcome = () => {
  const navigate = useNavigate();

  const onClickStart = () => {
    trackWelcomeScreen();
    acceptWelcome();
    navigate("/login", { replace: true });
  };

  return (
    <div className={styles.root}>
      <Image className={styles.logo} alt="logo" src="/assets/logoVertical-128.png" />

      <Text as="h1" block className={styles.title}>
        Welcome
      </Text>

      <Text as="p" block className={styles.message} variant="mediumPlus">
        Sparta for Excel provides unrestricted access to Sparta&apos;s cutting-edge live curves in your Excel
        environment.
      </Text>

      <IconList
        className={styles.list}
        items={[
          {
            icon: "TextDocument",
            primaryText: "Import your portfolios and products",
          },
          {
            icon: "Clock",
            primaryText: "Automatic live updates",
          },
          {
            icon: "Variable",
            primaryText: "Integrate and manipulate",
          },
        ]}
      />

      <PrimaryButton className={styles.button} onClick={onClickStart}>
        Start
      </PrimaryButton>
    </div>
  );
};
