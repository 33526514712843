import { useState } from "react";
import { getTokenError, removeTokenError } from "utils/token";

export default () => {
  const [hidden, setHidden] = useState(!getTokenError());

  const closeFn = () => {
    removeTokenError();
    setHidden(true);
  };

  const tokenError = getTokenError();

  return {
    hidden,
    closeFn,
    title: tokenError?.title || "",
    description: tokenError?.description || "",
  };
};
