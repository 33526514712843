import { FC } from "react";
import { trackLogoutClicked } from "services/auth/segment";
import useTaskPaneContext from "taskpane/context";

import { Link } from "../Link/Link";

type Props = {
  page: string;
};

export const Logout: FC<Props> = ({ page }) => {
  const { logout } = useTaskPaneContext();

  return (
    <Link
      onClick={() => {
        trackLogoutClicked(page);
        logout();
      }}
    >
      Logout
    </Link>
  );
};
