import { FC, useEffect, useState } from "react";
import { CircleFillIcon, PauseIcon } from "@fluentui/react-icons-mdl2";
import clsx from "clsx";

import styles from "./SocketIndicator.module.scss";

type Props = {
  className?: string;
};

const Icons = {
  connected: { label: "Live", Icon: CircleFillIcon },
  connecting: { label: "Connecting", Icon: CircleFillIcon },
  disconnected: { label: "Not Connected", Icon: PauseIcon },
};

export const SocketIndicator: FC<Props> = ({ className }) => {
  const [status, setStatus] = useState<ReturnType<typeof global.Sparta.getSocketStatus>>(
    global.Sparta.getSocketStatus()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setStatus(global.Sparta.getSocketStatus());
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const { label, Icon } = Icons[status];

  return (
    <div className={clsx(styles.root, className)}>
      <div className={clsx(styles.content, styles[status])}>
        <Icon className={styles.icon} />
        <span className={styles.label}>{label}</span>
      </div>
    </div>
  );
};
