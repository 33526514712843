import { FC } from "react";
import { ISearchBoxProps, SearchBox, Text } from "@fluentui/react";
import clsx from "clsx";

import styles from "./SearchInput.module.scss";

type Props = {
  value: string;
  onChange?: (value: string) => void;
  error?: boolean;
} & Omit<ISearchBoxProps, "value" | "onChange" | "showIcon">;

export const SearchInput: FC<Props> = ({ error, placeholder = "Search", onChange, value, ...rest }) => (
  <div className={clsx(styles.root, error && styles.error)}>
    <SearchBox
      placeholder={placeholder}
      onChange={(ev) => onChange && onChange(ev?.target.value || "")}
      showIcon
      styles={{ root: styles.input }}
      value={value}
      {...rest}
    />
    <Text className={styles.message}>No results. Adjust search criteria</Text>
  </div>
);
