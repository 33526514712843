import { Navigate } from "react-router-dom";
import useTaskPaneContext from "taskpane/context";
import { isEntitlementEnabled } from "utils/license";

export const Home = () => {
  const { user } = useTaskPaneContext();

  if (user) {
    if (isEntitlementEnabled(user, "liveCurves")) return <Navigate to="liveCurves/portfolios" replace />;
  }

  return <div />;
};
