import { FC } from "react";

type Props = {
  label: string;
  unit: string;
  styles?: Partial<{
    text: string;
    unit: string;
  }>;
};

const CheckboxLabel: FC<Props> = ({ label, styles, unit }) => (
  <span className={styles?.text}>
    {label} <span className={styles?.unit}>({unit})</span>
  </span>
);

export default CheckboxLabel;
