import { SpartaAPI } from "classes/SpartaAPI";
import { DATA_PROVIDERS } from "commands/constants";
import { Provider } from "services/liveCurves/types";
import { Nullable } from "types";
import { getEntries, getNumberFormatByDecimalPlaces, getTenorNameByCode } from "utils/helpers";

type KeyValue<Key extends string = string, Value = string | undefined> = Record<Key, Value>;

type PRODUCT_INFO_KEYS = "CODE" | "NAME" | "DATA_PROVIDER";
type PERIOD_KEYS = "TENOR" | "DATE";

const KEYVALUE_SEPARATOR = ":";
const PAIR_SEPARATOR = "|";

type SerializeFunction<Key extends string = string> = (keyValues: Partial<KeyValue<Key, Nullable<string>>>) => string;

export const serializeParam: SerializeFunction = (keyValues) =>
  Object.entries(keyValues)
    .filter(({ 1: value }) => !!value)
    .map(([key, value]) => `${key}${KEYVALUE_SEPARATOR}${value}`)
    .join(PAIR_SEPARATOR);

export const deserializeParam = <Key extends string = string>(param: string): KeyValue<Key> =>
  param.split(PAIR_SEPARATOR).reduce((res, keyValue) => {
    const [key, value] = keyValue.split(KEYVALUE_SEPARATOR);

    return {
      ...res,
      [key]: value,
    };
  }, {} as KeyValue<Key>);

export const serializeProductInfo: SerializeFunction<PRODUCT_INFO_KEYS> = (keyValues) => serializeParam(keyValues);

export const getParam = <Key extends string>(args: string, param: Key): string | undefined => {
  const params = deserializeParam<Key>(args);

  return params[param];
};

export const deserializeProductInfo = (productInfo: string) => deserializeParam<PRODUCT_INFO_KEYS>(productInfo);

export const getProductCode = (productInfo: string): string | undefined => {
  const { CODE, DATA_PROVIDER, NAME } = deserializeProductInfo(productInfo);

  if (CODE) return CODE;

  if (!NAME) return undefined;

  const providerCode = parseDataProvider(DATA_PROVIDER);

  return SpartaAPI.getProductCodeByLabelAndProvider(NAME, providerCode, global.Sparta.isObEnabled);
};

export const serializePeriod: SerializeFunction<PERIOD_KEYS> = (keyValues) => serializeParam(keyValues);

const computePeriodValue = (key: PERIOD_KEYS, value: string | undefined): string | undefined => {
  if (!value) {
    return undefined;
  }

  switch (key) {
    case "TENOR":
      return getTenorNameByCode(value);
    case "DATE":
      return value;
    default:
      return undefined;
  }
};

export const deserializePeriod = (period: string): [PERIOD_KEYS, string | undefined] => {
  const keyValues = deserializeParam<PERIOD_KEYS>(period);

  const { DATE, TENOR } = getEntries(keyValues).reduce(
    (res, [key, value]) => ({ ...res, [key]: computePeriodValue(key, value) }),
    {} as KeyValue<PERIOD_KEYS>
  );

  if (DATE) return ["DATE", DATE];

  return ["TENOR", TENOR];
};

const parseDataProvider = (dataProvider: string | undefined): Provider => {
  const providerCode = DATA_PROVIDERS.find(({ code }) => code === dataProvider)?.code;

  return providerCode ?? null;
};

/**
 * Add the provider to the product info if it is not present
 * @param productInfo Current product info argument
 * @param address Cell address
 * @returns true if the cell has been updated, false otherwise
 */
export const updateCellFormulaIfNeeded = (productInfo: string, address: string | undefined): boolean => {
  const { NAME, DATA_PROVIDER } = deserializeProductInfo(productInfo);

  if (DATA_PROVIDER || !address || !global.Sparta.isObEnabled || !NAME) return false;

  const metadatas = Object.values(SpartaAPI.getProductsByLabel(NAME));

  if (!metadatas.length) return false;

  // Priority to DP_1
  const providerCode =
    metadatas.find(({ dataProvider }) => dataProvider === "DP_1")?.dataProvider || metadatas[0].dataProvider;

  if (!providerCode) return false;

  let [sheet, cell] = address.split("!");

  // https://learn.microsoft.com/en-us/office/dev/add-ins/excel/custom-functions-parameter-options?tabs=javascript#invocation-parameter
  sheet = sheet.replace(/'(?!')/g, "");

  Excel.run(async (context) => {
    const range = context.workbook.worksheets.getItem(sheet.replace(/'(?!')/g, "")).getRange(cell);

    range.load("formulas");

    await context.sync();

    range.formulas = [
      [range.formulas[0][0].replace(productInfo, serializeProductInfo({ NAME, DATA_PROVIDER: providerCode }))],
    ];

    return context.sync();
  });

  return true;
};

export const stringCell = (basicValue = ""): Excel.StringCellValue => ({
  type: "String",
  basicValue,
});

export const numberCell = (basicValue: number, decimalPlaces = 0): Excel.FormattedNumberCellValue => ({
  type: "FormattedNumber",
  basicValue,
  numberFormat: getNumberFormatByDecimalPlaces(decimalPlaces),
});
