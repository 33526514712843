import { FC } from "react";
import { Link, Pivot, PivotItem, Text } from "@fluentui/react";

import { GuideItems } from "./components";
import { trackGuideTabsClicked, trackUserGuideLinkClicked } from "./segment";

import styles from "./Guide.module.scss";

type Props = {
  defaultTab?: "portfolios" | "products";
  onBack?: () => void;
};

export const Guide: FC<Props> = ({ defaultTab = "portfolios", onBack }) => (
  <div className={styles.root}>
    <div className={styles.content}>
      <Pivot
        styles={{
          root: styles.pivotRoot,
          link: styles.pivotLink,
        }}
        defaultSelectedKey={defaultTab}
        onLinkClick={(item) => {
          if (item?.props.headerText) {
            trackGuideTabsClicked(item.props.headerText as (typeof GuideItems)[number]["headerText"]);
          }
        }}
      >
        {GuideItems.map(({ children, headerText, itemKey }) => (
          <PivotItem key={itemKey} itemKey={itemKey} headerText={headerText}>
            {children}
          </PivotItem>
        ))}
      </Pivot>
      {onBack && (
        <a className={styles.back} onClick={onBack}>
          Back
        </a>
      )}
    </div>
    <Text className={styles.footer}>
      For more detailed information go to{" "}
      <Link
        href="https://knowledge.spartacommodities.com/how-to-use-the-excel-plugin"
        onClick={trackUserGuideLinkClicked}
        target="_blank"
      >
        User Guide
      </Link>
    </Text>
  </div>
);
