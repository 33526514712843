import * as React from "react";
import { FontIcon } from "@fluentui/react";
import { Text } from "@fluentui/react";
import clsx from "clsx";

import styles from "./IconList.module.scss";

export interface IconListItem {
  icon: string;
  primaryText: string;
}

export interface HeroListProps {
  className?: string;
  items: IconListItem[];
}

export const IconList = ({ className, items }: HeroListProps) => {
  const listItems = items.map((item, index) => (
    <li key={index}>
      <FontIcon aria-label={item.primaryText} iconName={item.icon} />

      <Text as="span" block className={styles.message} variant="mediumPlus">
        {item.primaryText}
      </Text>
    </li>
  ));

  return (
    <main className={clsx(className, styles.root)}>
      <ul>{listItems}</ul>
    </main>
  );
};
