import { track } from "segment";

/**
 * When the T&Cs pop-up is showed.
 */
export const trackTCShowed = (): void => {
  track("T&Cs Pop-up Showed");
};

/**
 * When the user clicks on the Done button of the T&Cs pop-up after marking the check.
 */
export const trackTCAgreed = (): void => {
  track("T&Cs Pop-up Agreed");
};
