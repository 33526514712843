import React from "react";
import { IPivotItemProps, IPivotProps, Pivot, PivotItem } from "@fluentui/react";

import styles from "./OriginTabs.module.scss";

type Props = {
  PivotItemProps?: IPivotItemProps;
  PivotProps?: IPivotProps;
  selectedTab?: string;
  showAllOrigins?: boolean;
  children: (name?: string) => React.ReactNode;
  onChange?: (name: string | undefined) => void;
  origins: string[];
};

/**
 * OriginTabs will show a list of tabs, and it will send to its child the name of the selected tab.
 * @param {(name?: string) => React.ReactNode} children The child is a function that get the name of the selected tab.
 * @param {string[]} origins An array with the origins. There is no need for the "All" section, as the component will add it.
 * @param {IPivotProps} [PivotProps] ? Direct access to the Pivot props.
 * @param {IPivotItemProps} [PivotItemProps] ? Direct access to the PivotItem props.
 * @param {string | undefined} selectedTab ? The selected tab.
 * @param {(name: string | undefined) => void} onChange ? A function to change the selected tab.
 *
 * @return JSX.Element :
 * * If there is origins, it'll return the child wrapped in the Pivot and PivotItem components.
 * * If there is NO origins, it'll return the child wrapped in a React.Fragment.
 *
 * @example
 *  <OriginTabs origins={origins}>
 *    {(originName) => (
 *      <ul>
 *        {products
 *          .filter(originName ? (p) => p.origin === originName : () => true)
 *          .map(({ shortName, unit, code }) => {
 *            return (
 *              <li key={code}>
 *                <Checkbox
 *                  label={shortName}
 *                />
 *              </li>
 *            );
 *          })}
 *      </ul>
 *    )}
 *  </OriginTabs>
 */

export const OriginTabs = ({
  children,
  origins,
  showAllOrigins = false,
  PivotProps,
  PivotItemProps,
  onChange,
  selectedTab,
}: Props) => {
  return origins.length > 0 ? (
    <Pivot
      className={styles.root}
      {...PivotProps}
      onLinkClick={(item) => {
        onChange && onChange(item?.props?.headerText);
      }}
      selectedKey={selectedTab}
    >
      {showAllOrigins && (
        <PivotItem itemKey="All" headerText="All" {...PivotItemProps}>
          {children()}
        </PivotItem>
      )}
      {origins.map((name) => (
        <PivotItem key={name} itemKey={name} headerText={name} {...PivotItemProps}>
          {children(name)}
        </PivotItem>
      ))}
    </Pivot>
  ) : (
    <>{children()}</>
  );
};
