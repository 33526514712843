import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SegmentProvider } from "react-segment-hooks";
import { page, segmentEnabledEnvs } from "segment";

const SegmentWrapper: FCC = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    page();
  }, [pathname]);

  if (
    process.env.SPARTA_ENV &&
    process.env.REACT_APP_SEGMENT_API_KEY &&
    segmentEnabledEnvs.indexOf(process.env.SPARTA_ENV) >= 0
  ) {
    return <SegmentProvider apiKey={process.env.REACT_APP_SEGMENT_API_KEY}>{children}</SegmentProvider>;
  }
  return <>{children}</>;
};

export default SegmentWrapper;
