import { datadogRum } from "@datadog/browser-rum";

export default function datadogRumInit() {
  const { APP_VERSION, SPARTA_ENV, DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN, DATADOG_SITE } = process.env;

  if (!SPARTA_ENV) return;
  if (!DATADOG_APPLICATION_ID) return;
  if (!DATADOG_CLIENT_TOKEN) return;
  if (!DATADOG_SITE) return;

  if (["staging", "production"].includes(SPARTA_ENV)) {
    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: DATADOG_SITE,
      service: "sparta-for-excel-addin",
      env: SPARTA_ENV === "production" ? "prod" : "staging",
      // Specify a version number to identify the deployed version of your application in Datadog
      version: APP_VERSION ?? "dev",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0, // Disable session replay
      trackResources: true,
      trackUserInteractions: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });
  }
}
