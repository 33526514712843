import { track } from "segment";

import { GuideItems } from "./components";

/**
 * When the user clicks on any of the tabs of the guide: ‘Portfolio’, ‘Products’ or ‘Formulas’.
 * @param tab Clicked tab
 */
export const trackGuideTabsClicked = (tab: (typeof GuideItems)[number]["headerText"]): void => {
  track("Guide Tabs Clicked", { tab });
};

/**
 * When the user clicks on the link to the User Guide inside the Guide.
 */
export const trackUserGuideLinkClicked = (): void => {
  track("User Guide Link Clicked");
};
