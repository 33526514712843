import { ComponentProps, useState } from "react";
import { Link, MessageBar as FMessageBar, MessageBarType } from "@fluentui/react";

import styles from "./MessageBar.module.scss";

type LocalStorageKey = string;

type Props = {
  actions?: ComponentProps<typeof FMessageBar>["actions"];
  className?: string;
  permanentDismiss?: LocalStorageKey;
  onDismiss?: () => void;
  track?: () => void;
  type?: MessageBarType;
};

export const MessageBar: FCC<Props> = ({
  actions,
  className,
  children,
  permanentDismiss,
  onDismiss,
  track,
  type = MessageBarType.warning,
}) => {
  const [dismiss, setDismiss] = useState(permanentDismiss ? !!localStorage.getItem(permanentDismiss) : false);

  const handleDismiss = () => {
    setDismiss(true);

    if (onDismiss) onDismiss();
  };

  const handlePermanentDismiss = () => {
    if (permanentDismiss) {
      localStorage.setItem(permanentDismiss, "true");
      handleDismiss();
    }
  };

  if (dismiss) return null;

  if (track) track();

  return (
    <FMessageBar
      actions={
        actions || permanentDismiss ? (
          <div>
            {actions}
            {permanentDismiss ? (
              <Link className={styles.dismiss} onClick={handlePermanentDismiss}>
                Don{"'"}t show this message again
              </Link>
            ) : undefined}
          </div>
        ) : undefined
      }
      className={className}
      dismissButtonAriaLabel="Close"
      messageBarType={type}
      onDismiss={handleDismiss}
      styles={styles}
    >
      {children}
    </FMessageBar>
  );
};
