import React from "react";
import { addPortfolioAsSheet, requestPrices } from "commands/commands";
import { getExistingSheetNames, getSheetNameForPortfolio } from "commands/utils";
import { getPortfoliosDetails } from "services";
import { Checkbox } from "types";

export type ExceptionPortfolioErrorType<T = Checkbox[]> = {
  portfolios: T;
  message: string;
  toString: () => string;
  type: "EXCEL" | "NETWORK" | "PRODUCT_BLOCKED" | "ERR_NETWORK";
};
export function ExceptionPortfolioError(
  failedPortfolios: ExceptionPortfolioErrorType["portfolios"],
  type: ExceptionPortfolioErrorType["type"]
): ExceptionPortfolioErrorType {
  const portfoliosNames = `'${failedPortfolios.map((f) => f.name).join("', '")}'`;
  return {
    portfolios: failedPortfolios,
    message: "One or more portfolios has failed to be added as a sheet to the workbook.",
    toString() {
      return `Error: ${
        failedPortfolios.length > 0 ? portfoliosNames : "The portfolios have"
      } failed to download, please try again. If the error persists, please contact success@spartacommodities.com`;
    },
    type,
  };
}

export function ExceptionPortfolioWithForbiddenProduct(
  failedPortfolios: string[]
): ExceptionPortfolioErrorType<string[]> {
  return {
    portfolios: failedPortfolios,
    message: `Error: One or more of the products in "${failedPortfolios.join(
      ", "
    )}" is not supported for Excel and has not been downloaded. For further queries on why you are receiving this message, please contact success@spartacommodities.com.`,
    toString() {
      return this.message;
    },
    type: "PRODUCT_BLOCKED",
  };
}

export function ExceptionPortfolioBackendError(): ExceptionPortfolioErrorType {
  return {
    portfolios: [],
    message:
      "Error: Sparta for Excel has encountered an unknown error, please try again. If the error persists, " +
      "please contact success@spartacommodities.com.",
    toString() {
      return this.message;
    },
    type: "NETWORK",
  };
}

export function ExceptionNetworkError(): ExceptionPortfolioErrorType {
  return {
    portfolios: [],
    message: "Error: There is no connection to Sparta",
    toString() {
      return this.message;
    },
    type: "ERR_NETWORK",
  };
}

export const injectPortfolios = async (
  selectedPortfolios: Checkbox[],
  setError: React.Dispatch<React.SetStateAction<ExceptionPortfolioErrorType<any> | undefined>>
) => {
  const portfoliosIds = selectedPortfolios.map(({ id }) => id);

  let errorList: Checkbox[] = [];
  const details = await getPortfoliosDetails(portfoliosIds);

  await requestPrices([
    ...new Set(details.flatMap(({ liveCurves }) => liveCurves.map(({ code, type }) => ({ code, type })))),
  ]);

  if (portfoliosIds.length !== details.length) {
    errorList = selectedPortfolios.filter(({ id }) => !details.some((d) => d.id === id));
    throw ExceptionPortfolioError(errorList, "NETWORK");
  }

  const blockedPortfoliosByForbiddenProducts: { name: string; products: string[] }[] = details.flatMap((detail) => {
    const products = detail.liveCurves.flatMap((curve) => (curve.disabled ? curve.shortName : []));
    if (products.length > 0)
      return {
        name: detail.name,
        products,
      };
    return [];
  });

  if (blockedPortfoliosByForbiddenProducts.length > 0) {
    setError(ExceptionPortfolioWithForbiddenProduct(blockedPortfoliosByForbiddenProducts.map(({ name }) => name)));
  }

  const existingNames = await getExistingSheetNames();

  await Promise.all(
    details.flatMap(async (portfolio) => {
      const liveCurves = portfolio.liveCurves.flatMap((lc) => (lc.disabled ? [] : lc)); // Remove the disabled products from the liveCurve.

      if (liveCurves.length) {
        try {
          const sheetName = getSheetNameForPortfolio(portfolio.name, existingNames);
          existingNames.push(sheetName);
          return addPortfolioAsSheet({ ...portfolio, liveCurves }, sheetName);
        } catch (e) {
          errorList.push(e as Checkbox);
        }
      }

      return [];
    })
  );

  if (errorList.length) {
    throw ExceptionPortfolioError(errorList, "EXCEL");
  }
};
