const VERSION_QUERY_KEY = "v";
const FIRST_VERSION: Version = {
  major: 1,
  minor: 0,
  patch: 0,
};

type Version = {
  major: number;
  minor: number;
  patch: number;
};

/**
 * Parse a string version to a valid object
 * @param version version to parse, the valid format is `major.minor.patch`
 * @returns version parsed
 */
const parseVersion = (version: string | null): Version => {
  try {
    if (version === null) return FIRST_VERSION;

    const [major, minor, patch] = version.split(".").map((item) => parseInt(item));

    return {
      major,
      minor,
      patch,
    };
  } catch {
    // fallback to the first version
    return FIRST_VERSION;
  }
};

/**
 * Get the version of the active manifest
 * @returns Manifest version
 */
const getManifestVersion = (): Version => {
  const params = new URLSearchParams(location.search);

  return parseVersion(params.get(VERSION_QUERY_KEY));
};

/**
 * Compare a given version against the manifest one
 * @param version Version to be compared
 * @returns `lower` if the manifest is higher, `higher` if the compared is higher, `equal` if both are equals
 */
const compareAgainstManifest = (version: string): "higher" | "lower" | "equal" => {
  const manifestVersion = getManifestVersion();
  const comparedVersion = parseVersion(version);

  if (manifestVersion.major > comparedVersion.major) return "lower";

  if (manifestVersion.major < comparedVersion.major) return "higher";

  if (manifestVersion.minor > comparedVersion.minor) return "lower";

  if (manifestVersion.minor < comparedVersion.minor) return "higher";

  if (manifestVersion.patch > comparedVersion.patch) return "lower";

  if (manifestVersion.patch < comparedVersion.patch) return "higher";

  return "equal";
};

export const isFeatureAvailable = ({
  minVersion,
  maxVersion,
}: {
  minVersion?: string;
  maxVersion?: string;
}): boolean => {
  let available = true;

  if (minVersion) {
    available = compareAgainstManifest(minVersion) !== "higher";
  }

  if (maxVersion && available) {
    available = compareAgainstManifest(maxVersion) !== "lower";
  }

  return available;
};
