import { FC } from "react";
import { ILinkProps, Link as FluentLink } from "@fluentui/react";
import clsx from "clsx";

import styles from "./Link.module.scss";

export const Link: FC<ILinkProps> = ({ className, children, ...rest }) => (
  <FluentLink className={clsx(styles.root, className)} {...rest}>
    {children}
  </FluentLink>
);
