import { createContext, useContext } from "react";

import { Context } from "./types";

export const TaskPaneContext = createContext<Context>({
  isLogged: false,
  login: () => {},
  loginFromDialog: () => {},
  logout: () => {},
  shouldBeWelcome: false,
  user: undefined,
});

export const useTaskPaneContext = () => useContext(TaskPaneContext);
