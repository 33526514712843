import React, { useEffect } from "react";
import { FontIcon, Text } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import clsx from "clsx";

import styles from "./CollapseList.module.scss";

type Props = {
  children?: React.ReactNode;
  disabled?: boolean;
  headerChildren?: React.ReactNode;
  isExpanded?: boolean;
  onToggle?: (expanded: boolean) => void;
  title: string;
};

export const CollapseList = ({
  children,
  disabled = false,
  isExpanded = false,
  onToggle,
  headerChildren,
  title,
}: Props) => {
  const [expanded, { toggle }] = useBoolean(isExpanded);

  const handleToggle = () => {
    if (!disabled) toggle();
  };

  useEffect(() => {
    if (onToggle) {
      onToggle(expanded);
    }
  }, [expanded]);

  return (
    <div className={styles.root}>
      <button disabled={disabled} className={styles.header} onClick={handleToggle}>
        <FontIcon iconName={expanded ? "ChevronDown" : "ChevronRight"} />
        <Text className={clsx(styles.title, expanded && styles.expanded)}>{title}</Text>
        {headerChildren}
      </button>

      <div className={clsx(styles.content, expanded && styles.expanded)}>{children}</div>
    </div>
  );
};
