import { FC, useEffect } from "react";
import { Text } from "@fluentui/react";
import { trackInfoFrequencyMessageShown } from "segment/ribbon";
import { MessageBar } from "taskpane/components";
import { getFrequencyValue } from "utils/helpers";
import { HIDE_INFO_FREQUENCY_KEY } from "utils/localStorage";
import { isFeatureAvailable } from "utils/version";

type Props = {
  onDismiss?: () => void;
};

export const UpdateFrequencyMessage: FC<Props> = ({ onDismiss }) => {
  useEffect(() => {
    if (isFeatureAvailable({ minVersion: "1.0.1" }) && !localStorage.getItem(HIDE_INFO_FREQUENCY_KEY)) {
      trackInfoFrequencyMessageShown();
    }
  }, []);

  if (!isFeatureAvailable({ minVersion: "1.0.1" })) return null;

  return (
    <MessageBar permanentDismiss={HIDE_INFO_FREQUENCY_KEY} onDismiss={onDismiss}>
      Info: Running large data models or multiple add-ins could lead to performance degradation. We have set{" "}
      <Text style={{ fontSize: "inherit", fontWeight: "bold" }}>
        Recommended - Update frequency {getFrequencyValue("recommended", global.Sparta.user, "s")}s
      </Text>{" "}
      as default. The update frequency can be changed to{" "}
      <Text style={{ fontSize: "inherit", fontWeight: "bold" }}>Live</Text> or{" "}
      <Text style={{ fontSize: "inherit", fontWeight: "bold" }}>
        Idle - Update frequency {getFrequencyValue("idle", global.Sparta.user, "s")}s
      </Text>{" "}
      in the main ribbon menu above.
    </MessageBar>
  );
};
