import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Subscription } from "classes/Subscription";

import { RoutesManager } from "./components";
import { useLiveWarning } from "./hooks";
import { privateRoutes, publicRoutes } from "./routes";

const App = () => {
  const warningModal = useLiveWarning();
  const navigate = useNavigate();

  useEffect(() => {
    let subscription: Subscription<string>;

    // Prevent race condition
    const interval = setInterval(() => {
      if (global.Sparta) {
        clearInterval(interval);

        subscription = global.Sparta.activeRoute.subscribe((route) => {
          if (route) navigate(route);
        });
      }
    }, 1000);

    return () => subscription.unsubscribe();
  }, []);

  return (
    <>
      <Routes>
        <Route element={<RoutesManager authenticationRequired />}>
          {privateRoutes.map((props) => (
            <Route key={props.path} {...props} />
          ))}
        </Route>
        <Route element={<RoutesManager />}>
          {publicRoutes.map((props) => (
            <Route key={props.path} {...props} />
          ))}
        </Route>
      </Routes>
      {warningModal}
    </>
  );
};

export default App;
