import { RouteProps } from "react-router-dom";
import { Entitlements } from "services/auth/types";

import { Guide, ProtectedView } from "./components";
import { Home, LiveCurves, Login, Redirect, Welcome } from "./views";

export const publicRoutes: RouteProps[] = [
  {
    element: <Login />,
    path: "/login",
  },
  {
    element: <Welcome />,
    path: "/welcome",
  },
];

const protectedView = (Component: React.ComponentType, entitlement: Entitlements) => (
  <ProtectedView Component={Component} entitlement={entitlement} />
);

export const privateRoutes: RouteProps[] = [
  {
    element: <Home />,
    path: "/",
  },
  {
    element: protectedView(LiveCurves.Component, "liveCurves"),
    path: "/liveCurves",
    children: LiveCurves.Router,
  },
  {
    element: protectedView(Guide, "liveCurves"),
    path: "/guide",
  },
  {
    element: <Redirect />,
    path: "/redirect",
  },
];
