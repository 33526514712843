import { Product } from "services/liveCurves/types";

import { CategoryMapped, Filters } from "./hooks";

export const getProductsByFiltersKey = (key: string, categories: CategoryMapped[]) => {
  const [categoryId, groupId, rawOrigin] = key.split("-");

  const originKey = ["All", ""].includes(rawOrigin) ? undefined : rawOrigin;

  return (
    categories
      .find(({ id }) => id === parseInt(categoryId, 10))
      ?.productGroups.find(({ id }) => id === parseInt(groupId, 10))
      ?.products.filter(({ origin }) => !originKey || origin === originKey) || []
  );
};

export const generateOptions = (filterKey: string, filters: Filters, products: Product[]) =>
  [
    ...products.reduce((options, product) => {
      const filterValues = product.filters;

      if (filterValues) {
        const matchCurrentFilters = Object.entries(filters).every(
          ([key, { value }]) =>
            filterKey === key || !value || !filterValues[key] || filterValues[key].value.includes(value)
        );

        if (matchCurrentFilters) {
          const values = filterValues[filterKey];

          if (values) {
            values.value.forEach((value) => options.add(value));
          }
        }
      }

      return options;
    }, new Set<string>()),
  ].sort();

export const searchByFilters = (filters: Filters | undefined, products: Product[]) =>
  filters && Object.values(filters).some(({ value }) => !!value)
    ? products.filter((product) =>
        Object.entries(filters).every(
          ([key, { value }]) =>
            !value || !product.filters || !product.filters[key] || product.filters[key].value.includes(value)
        )
      )
    : products;
