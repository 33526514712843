import { useEffect, useMemo, useState } from "react";
import { AxiosError } from "axios";
import { getPortfolios } from "services";
import { Portfolio } from "services/liveCurves/types";
import { Checkbox } from "types";
import { filterByText } from "utils/helpers";

import {
  ExceptionNetworkError,
  ExceptionPortfolioBackendError,
  ExceptionPortfolioError,
  ExceptionPortfolioErrorType,
  injectPortfolios,
} from "./utils";

export default (filter: string) => {
  const [selected, setSelected] = useState<Checkbox[]>([]);
  const [data, setData] = useState<Portfolio[]>([]);
  const [error, setError] = useState<ExceptionPortfolioErrorType<any> | undefined>();
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getPortfolios();

        if (result.length === 0) throw new AxiosError();

        setData(result);
      } catch (e) {
        if (e instanceof AxiosError) {
          if (e.code === "ERR_NETWORK") {
            setError(ExceptionNetworkError());
            return setIsDownloading(false);
          } else {
            setError(ExceptionPortfolioBackendError());
          }
        }

        setIsDownloading(false);
        setData([]);
      }
    };

    getData();

    const interval = setInterval(getData, 60_000);

    return () => clearInterval(interval);
  }, []);

  const filteredPortfolios = useMemo(() => data.filter(({ name }) => filterByText(name, filter)), [data, filter]);

  const filterError = !!filter && !filteredPortfolios.length;

  const onClickCheckbox = (id: number, name: string) => {
    const existingItem = selected.findIndex(({ id: code }) => code === id);

    if (existingItem !== -1) {
      // Uncheck
      setSelected([...selected.filter((_, idx) => idx !== existingItem)]);
    } else {
      // Check
      setSelected([...selected, { id, name }]);
    }
  };

  const onClickAdd = async (selectedPortfolios: Checkbox[] = selected) => {
    setError(undefined);
    const loaderTimeout = setTimeout(() => setIsDownloading(true), 1000);

    try {
      await injectPortfolios(selectedPortfolios, setError);
    } catch (error: any) {
      if (error instanceof AxiosError) {
        setError(ExceptionPortfolioError(selected, "NETWORK"));
      } else if (error.type === "PRODUCT_BLOCKED") {
        setError(error);
      } else {
        try {
          await onClickAdd(
            (error as ReturnType<typeof ExceptionPortfolioError>).portfolios.map((e) => ({ id: e.id, name: "Sparta" }))
          );
        } catch (e) {
          const error = e as ReturnType<typeof ExceptionPortfolioError>;
          setError(error);
        }
      }
    }

    setSelected([]);
    clearTimeout(loaderTimeout);
    setIsDownloading(false);
  };

  return {
    cleanError: () => setError(undefined),
    error,
    filterError,
    isDownloading,
    onClickAdd,
    onClickCheckbox,
    portfolios: filterError ? data : filteredPortfolios,
    selected,
  };
};
