import { FC } from "react";
import { DefaultButton, Dialog as FDialog, DialogFooter, DialogType } from "@fluentui/react";

import { PrimaryButton } from "../PrimaryButton/PrimaryButton";

import styles from "./Dialog.module.scss";

type Props = {
  closeFn?: () => void;
  defaultButtonFn?: () => void;
  defaultText?: string;
  description: string;
  hidden: boolean;
  primaryButtonFn?: () => void;
  primaryText?: string;
  title: string;
  footerChildren?: React.ReactNode;
};

export const Dialog: FC<Props> = ({
  closeFn,
  defaultButtonFn,
  defaultText,
  description,
  hidden,
  primaryButtonFn,
  primaryText,
  title,
  footerChildren,
}) => {
  const onDismiss = () => {
    if (closeFn) {
      closeFn();
    }
  };

  const onClickPrimary = () => {
    if (primaryButtonFn) {
      primaryButtonFn();
    }

    onDismiss();
  };

  const onClickDefault = () => {
    if (defaultButtonFn) {
      defaultButtonFn();
    }

    onDismiss();
  };

  return (
    <FDialog
      dialogContentProps={{
        styles,
        subText: description,
        title,
        type: DialogType.close,
      }}
      hidden={hidden}
      modalProps={{
        isBlocking: true,
      }}
      onDismiss={onDismiss}
      styles={{
        main: styles.main,
      }}
    >
      <DialogFooter styles={{ actionsRight: styles.footer, action: styles.footerAction }}>
        <div>{footerChildren}</div>
        <div className={styles.footerRight}>
          {primaryText && <PrimaryButton onClick={onClickPrimary} text={primaryText} />}
          {defaultText && <DefaultButton onClick={onClickDefault} text={defaultText} />}
        </div>
      </DialogFooter>
    </FDialog>
  );
};
