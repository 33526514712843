import { useState } from "react";
import { Checkbox } from "@fluentui/react";
import { useSetInterval } from "@fluentui/react-hooks";
import { trackFrequencyLiveButtonClick } from "segment/ribbon";
import { Dialog } from "taskpane/components";
import { dismissLiveFrequencyWarning, hideLiveFrequencyWarning, isLiveFrequencyWarningShown } from "utils/localStorage";

export const useLiveWarning = () => {
  const [showWarning, setShowWarning] = useState(isLiveFrequencyWarningShown());
  const [permanentDismiss, setPermanentDismiss] = useState(false);
  const { setInterval } = useSetInterval();

  setInterval(() => {
    setShowWarning(isLiveFrequencyWarningShown());
  }, 100);

  return showWarning ? (
    <Dialog
      hidden={!showWarning}
      closeFn={() => {
        hideLiveFrequencyWarning();
        setShowWarning(false);
      }}
      description="Running large data models or multiple add-ins could lead to performance degradation or system instability. We do not recommend live updates for large Excel sheets or when running multiple add-ins."
      title="Warning"
      primaryText="Ok"
      primaryButtonFn={() => {
        trackFrequencyLiveButtonClick(global.Sparta.updateFrequency);
        global.Sparta.updateFrequency = "live";

        if (permanentDismiss) {
          dismissLiveFrequencyWarning();
        }
      }}
      defaultText="Cancel"
      footerChildren={
        <Checkbox
          label="Don't show this message again"
          checked={permanentDismiss}
          onChange={(_ev, checked) => setPermanentDismiss(!!checked)}
        />
      }
    />
  ) : null;
};
