const buildUrl = (url: string) => {
  const { protocol, hostname, port } = location;

  return `${protocol}//${hostname}${port ? `:${port}` : ""}${url}`;
};

export const openDialog = (url: string, _onSuccess: () => void, _onFailed: () => void) => {
  let dialog: Office.Dialog;

  const processEventReceived = ({ error }: any) => {
    switch (error) {
      case 12006:
      default:
        _onFailed();
    }
  };

  const processMessageReceived = ({ message }: any) => {
    switch (message) {
      case "success":
        _onSuccess();
        break;
      case "failed":
      default:
        _onFailed();
        break;
    }

    dialog.close();
  };

  Office.context.ui.displayDialogAsync(buildUrl(url), { height: 85, width: 51 }, ({ status, value }) => {
    if (status === Office.AsyncResultStatus.Succeeded) {
      dialog = value;

      dialog.addEventHandler(Office.EventType.DialogEventReceived, processEventReceived);
      dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessageReceived);
    }
  });
};
