import { FC, useEffect, useMemo, useState } from "react";
import { Dropdown, FontIcon, IDropdownOption, Panel, ResponsiveMode, Text } from "@fluentui/react";
import { Footer, Link, PrimaryButton } from "taskpane/components";

import { CategoryMapped, Filters } from "../../hooks";
import { generateOptions, getProductsByFiltersKey } from "../../utils";

import styles from "./ApplyFilters.module.scss";

type Props = {
  filtersKey: string;
  filters?: Filters;
  onAccept: (filtersKey: string, newFilters: Filters) => void;
  onClose: () => void;
  categories?: CategoryMapped[];
};

export const ApplyFilters: FC<Props> = ({ filtersKey, filters, onAccept, onClose, categories = [] }) => {
  const [newFilters, setNewFilters] = useState(filters);

  useEffect(() => {
    setNewFilters(filters);
  }, [filters]);

  const optionsByKey = useMemo(() => {
    const productsByKey = getProductsByFiltersKey(filtersKey, categories);

    return Object.keys(newFilters ?? {}).reduce(
      (res, filterKey) => ({
        ...res,
        [filterKey]: generateOptions(filterKey, newFilters ?? {}, productsByKey).map((option) => ({
          key: option,
          text: option,
        })),
      }),
      {} as Record<string, IDropdownOption[]>
    );
  }, [newFilters, categories, filtersKey]);

  const handleOnChangeSelect = (key: string, value: string) => {
    setNewFilters((prev) => (prev ? { ...prev, [key]: { ...prev[key], value: value || undefined } } : prev));
  };

  const handleOnAccept = () => {
    if (newFilters) {
      onAccept(filtersKey, newFilters);
    }
    onClose();
  };

  return (
    <Panel
      hasCloseButton={false}
      onDismiss={onClose}
      isFooterAtBottom
      isLightDismiss
      isOpen={!!newFilters}
      onRenderBody={() => (
        <div className={styles.body}>
          {Object.entries(newFilters ?? {})
            .sort(([, { order: orderA }], [, { order: orderB }]) => orderA - orderB)
            .map(([key, { title, value }]) => (
              <Dropdown
                styles={{
                  callout: styles.list,
                }}
                key={key}
                options={[{ text: "All", key: "" }, ...optionsByKey[key]]}
                onChange={(_, option) => (option ? handleOnChangeSelect(key, option.key as string) : null)}
                onRenderLabel={() => <Text className={styles.label}>{title}</Text>}
                responsiveMode={ResponsiveMode.xxxLarge}
                selectedKey={value || ""}
              />
            ))}
        </div>
      )}
      onRenderFooter={() => (
        <Footer className={styles.footer}>
          <Link onClick={onClose}>Cancel</Link>
          <PrimaryButton onClick={handleOnAccept}>Apply</PrimaryButton>
        </Footer>
      )}
      onRenderNavigation={() => (
        <div className={styles.header}>
          <FontIcon onClick={onClose} iconName="ChevronLeft" />
          <Text>Filters</Text>
          <div />
        </div>
      )}
    />
  );
};
