import { datadogRum } from "@datadog/browser-rum";
import { SpartaAPI } from "classes/SpartaAPI";
import type { Metadata } from "classes/types";
import { addMonths, differenceInMilliseconds, startOfMonth } from "date-fns";
import { utcNow } from "utils/helpers";

import { debugGeneral } from "./debug";
import {
  deserializePeriod,
  deserializeProductInfo,
  getProductCode,
  numberCell,
  stringCell,
  updateCellFormulaIfNeeded,
} from "./utils";

/**
 * Displays the requested product Property
 * @customfunction
 * @requiresAddress
 * @param args Custom arguments
 * @param productInfo Searialized info of the product.
 * @param property Product property to show
 *
 * @helpurl https://knowledge.spartacommodities.com/how-to-use-the-excel-plugin#formulas
 */
export function getProductMetadata(
  args: string,
  productInfo: string,
  property: string,
  invocation: CustomFunctions.Invocation
): Excel.StringCellValue {
  try {
    const debugReturn = debugGeneral(args);

    if (debugReturn !== null) {
      return debugReturn;
    }

    const productCode = getProductCode(productInfo);

    if (!productCode) {
      const updated = updateCellFormulaIfNeeded(productInfo, invocation.address);

      if (!updated) {
        datadogRum.addError("[getProductMetadata] Product not found", {
          args: {
            product: productInfo,
            property,
          },
          productCode,
        });
      }

      return stringCell();
    }

    const metadata = SpartaAPI.getProductMetadata(productCode);

    if (!metadata || Object.hasOwnProperty.call(metadata, property) === false) {
      datadogRum.addError("[getProductMetadata] Metadata not found", {
        args: {
          product: productInfo,
          property,
        },
        productCode,
        property,
      });

      return stringCell();
    }

    const observableProperties = ["name", "units", "type", "priceType"] satisfies (keyof Metadata)[];
    const castedProperty = property as (typeof observableProperties)[number];

    const isObservable = observableProperties.includes(castedProperty);

    if (!isObservable) {
      datadogRum.addError(`[getProductMetadata] Property is not observable`, {
        args: {
          product: productInfo,
          property,
        },
        productCode,
        property,
      });

      return stringCell();
    }

    return stringCell(metadata?.[castedProperty]);
  } catch (error) {
    datadogRum.addError("[getProductMetadata] Unexpected error", { error });

    return stringCell();
  }
}

/**
 * Displays the last value for a symbol
 * @customfunction
 * @streaming
 * @param args Custom arguments
 * @param productInfo Searialized info of the product.
 * @param period Searialized list of TENOR and DATE.
 *
 * @helpurl https://knowledge.spartacommodities.com/how-to-use-the-excel-plugin#formulas
 */
export function observeLiveCurve(
  args: string,
  productInfo: string,
  period: string,
  invocation: CustomFunctions.StreamingInvocation<Excel.CellValue[][]>
) {
  try {
    const debugReturn = debugGeneral(args);

    if (debugReturn !== null) {
      return invocation.setResult([[debugReturn]]);
    }

    const updateResult = () => {
      let productCode = getProductCode(productInfo) ?? "";

      if (!productCode) {
        const { DATA_PROVIDER, NAME } = deserializeProductInfo(productInfo);

        // Data provider is not present in the product info, so we need to infer the product only with the name
        if (!DATA_PROVIDER && NAME && global.Sparta.isObEnabled) {
          const products = Object.entries(SpartaAPI.getProductsByLabel(NAME));

          // Priority to DP_1
          productCode = products.find(([, { dataProvider }]) => dataProvider === "DP_1")?.[0] || products[0][0];
        }
      }

      const [, tenorName] = deserializePeriod(period);

      if (!productCode || !tenorName) {
        datadogRum.addError("[observeLiveCurve] Product or Tenor not found", {
          args: {
            product: productInfo,
            period,
          },
          productCode,
          tenorName,
        });

        return invocation.setResult([[stringCell()]]);
      }

      return global.Sparta.busForProduct(productCode, tenorName).subscribe((price) => {
        const metadata = SpartaAPI.getProductMetadata(productCode);

        if (price === null || !metadata) {
          if (!metadata) {
            datadogRum.addError("[observeLiveCurve] Metadata not found", {
              args: {
                product: productInfo,
                period,
              },
              productCode,
              tenorName,
            });
          }

          return invocation.setResult([[stringCell()]]);
        }

        if (metadata.priceType === "Datetime") {
          return invocation.setResult([[stringCell(price as string)]]);
        } else {
          return invocation.setResult([[numberCell(price as number, metadata?.decimalPlaces)]]);
        }
      });
    };

    let subscription = updateResult();

    let timeoutId: NodeJS.Timeout | undefined = undefined;
    const [tenorKey, tenorValue] = deserializePeriod(period);
    const someValidTenor = tenorKey === "TENOR" && tenorValue !== null;

    if (someValidTenor) {
      const now = utcNow();
      const nextMonth = startOfMonth(addMonths(now, 1));

      timeoutId = setTimeout(() => {
        subscription?.unsubscribe();
        subscription = updateResult();
      }, differenceInMilliseconds(nextMonth, now));
    }

    invocation.onCanceled = () => {
      clearTimeout(timeoutId);
      subscription?.unsubscribe();
    };
  } catch (error) {
    datadogRum.addError("[observeLiveCurve] Unexpected error", { error });
  }
}

/**
 * Displays month and day.
 * @customfunction
 * @streaming
 * @param args Custom arguments
 * @param period Searialized list of TENOR and DATE.
 *
 * @helpurl https://knowledge.spartacommodities.com/how-to-use-the-excel-plugin#formulas
 */
export function getPeriod(
  args: string,
  period: string,
  invocation: CustomFunctions.StreamingInvocation<Excel.StringCellValue>
) {
  try {
    const debugReturn = debugGeneral(args);

    if (debugReturn !== null) {
      return invocation.setResult(debugReturn);
    }

    const updateResult = () => {
      const [, tenorName] = deserializePeriod(period);

      invocation.setResult(stringCell(tenorName));
    };

    let timeoutId: NodeJS.Timeout | undefined = undefined;
    const [key, value] = deserializePeriod(period);
    const someValidTenor = key === "TENOR" && value !== null;

    if (someValidTenor) {
      const now = utcNow();
      const nextMonth = startOfMonth(addMonths(now, 1));

      timeoutId = setTimeout(updateResult, differenceInMilliseconds(nextMonth, now));
    }

    updateResult();
    invocation.onCanceled = () => clearTimeout(timeoutId);
  } catch (error) {
    datadogRum.addError("[getPeriod] Unexpected error", { error });
  }
}

CustomFunctions.associate("GETPRODUCTMETADATA", getProductMetadata);
CustomFunctions.associate("OBSERVELIVECURVE", observeLiveCurve);
CustomFunctions.associate("GETPERIOD", getPeriod);