import { track } from "segment";

/**
 * When the user clicks on the ‘Add’ button after having selected one or more products.
 * @param {boolean} monthColumn - If the form has selected 'monthColum'.
 * @param {string[]} products - Name of the products downloaded.
 */
export const trackDownloadProduct = (monthColumn: boolean, products: string[]): void => {
  track("Download Product", { monthColumn, products });
};

/**
 * When the info message is shown on the ‘Add Product’ screen to notify the user
 * that s/he has reached the maximum number of products per download.
 */
export const trackMaxNumberOfProducts = (): void => {
  track("Info Maximum Number Products");
};

/**
 * When the error message is shown on the ‘Add Product’ screen to notify the
 * user about an error while downloading products.
 */
export const trackErrorDownloadingProduct = (): void => {
  track("Error Downloading Product");
};

/**
 * When the error message is shown on the ‘Add Product’ screen to notify the
 * user about an error loading the screen.
 */
export const trackErrorAddProductScreen = (): void => {
  track("Error Add Product Screen");
};

/**
 * When the user clicks on the Retry button after having an error loading the ‘Add Product’ screen.
 */
export const trackRetryErrorAddProductScreen = (): void => {
  track("Retry Error Add Product Screen");
};

/**
 * When the info message is shown on the ‘Add Portfolio’ screen to notify the user that the portfolio that s/he
 * downloaded contains one or more products that are not allowed in Excel.
 */
export const trackInfoProductBlocked = (products: string[]): void => {
  track("Info Product Blocked", { products });
};
