import { useState } from "react";
import { injectProducts } from "commands/commands";

type Checkbox = {
  categoryOrder: number;
  groupOrder: number;
  productLabel: string;
  productCode: string;
};

const sorter = (a: Checkbox, b: Checkbox) => {
  if (a.categoryOrder === b.categoryOrder) {
    if (a.groupOrder === b.groupOrder) {
      return a.productLabel < b.productLabel ? -1 : 1;
    }

    return a.groupOrder < b.groupOrder ? -1 : 1;
  }

  return a.categoryOrder < b.categoryOrder ? -1 : 1;
};

export const useProductsForm = () => {
  const [selected, setSelected] = useState<Checkbox[]>([]);
  const [withDateColumn, setWithDateColumn] = useState(false);
  const [error, setError] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const isProductChecked = (code: string) => selected.some(({ productCode }) => productCode === code);

  const onClickCheckbox = (categoryOrder: number, groupOrder: number, productLabel: string, productCode: string) => {
    const existingItem = selected.findIndex(({ productCode: code }) => code === productCode);

    if (existingItem !== -1) {
      // Uncheck
      setSelected([...selected.filter((_, idx) => idx !== existingItem)]);
    } else {
      // Check
      setSelected([
        ...selected,
        {
          categoryOrder,
          groupOrder,
          productLabel,
          productCode,
        },
      ]);
    }
  };

  const onClickAdd = async () => {
    let loaderTimeout: NodeJS.Timeout | undefined = undefined;

    try {
      setError(false);
      const sorted = selected.sort(sorter);

      loaderTimeout = setTimeout(() => setIsDownloading(true), 2000);

      await injectProducts(
        sorted.map(({ productCode }) => productCode),
        withDateColumn
      );

      clearTimeout(loaderTimeout);
      setIsDownloading(false);

      setSelected([]);
      setWithDateColumn(false);
    } catch {
      clearTimeout(loaderTimeout);
      setIsDownloading(false);

      setError(true);
    }
  };

  return {
    cleanError: () => setError(false),
    error,
    isDownloading,
    isProductChecked,
    onClickCheckbox,
    onClickAdd,
    selected,
    maxLimitReached: selected.length === 40,
    selectedNumber: selected.length,
    toggleDateColumn: () => setWithDateColumn((prev) => !prev),
    withDateColumn,
  };
};
