import React, { useState } from "react";
import { Image, Link, Spinner, SpinnerSize, Text, TextField } from "@fluentui/react";
import { acceptTerms, me, officeLogin, trackAuthBlocked, trackLoginButtonClicked, trackLoginError } from "services";
import { Dialog, PrimaryButton } from "taskpane/components";
import useTaskPaneContext from "taskpane/context";
import { openDialog } from "utils/office";

import { EmailRegex, LOGIN_ERRORS } from "./constants";
import { trackTCAgreed, trackTCShowed } from "./segment";
import useSessionErrors from "./useSessionErrors";

import styles from "./Login.module.scss";

export const Login = () => {
  const [emailError, setEmailError] = useState(LOGIN_ERRORS.noError);
  const [passwordError, setPasswordError] = useState(LOGIN_ERRORS.noError);

  const [showSpinner, setShowSpinner] = useState(false);
  const [isPopUpShowed, setIsPopUpShowed] = useState(false);

  const { hidden, closeFn, title, description } = useSessionErrors();

  const { login, loginFromDialog, logout } = useTaskPaneContext();

  const startLogin = async (email: string, password: string) => {
    try {
      const response = await officeLogin({ email, password });
      const { excelTermsAndConditions } = await me(response.data.accessToken);

      if (!excelTermsAndConditions) {
        trackTCShowed();
        setIsPopUpShowed(true);
        openDialog(
          "/terms.html",
          () => {
            trackTCAgreed();
            loginFromDialog(response.data);
            acceptTerms();
            setShowSpinner(false);
          },
          () => {
            logout();
            setShowSpinner(false);
            setIsPopUpShowed(false);
          }
        );
      } else {
        setShowSpinner(true);
        login(response.data);
        setShowSpinner(false);
      }
    } catch (error: any) {
      setIsPopUpShowed(false);
      const status: string = error?.response?.status?.toString() || "";
      const is400Error = status.startsWith("4");

      if (!error?.response?.data?.code) {
        setPasswordError(LOGIN_ERRORS.unexpected);
      } else {
        switch (error.response.data.code) {
          case "excel_disabled":
            setPasswordError(error.response.data.message);
            break;
          case "user_blocked_web":
            trackAuthBlocked();
            setPasswordError(error.response.data.message);
            break;
          default:
            trackLoginError(email);
            setPasswordError(
              is400Error ? LOGIN_ERRORS.incorrectFields : error?.response?.data?.message || LOGIN_ERRORS.unexpected
            );
            break;
        }
      }
      setIsPopUpShowed(false);
      setShowSpinner(false);
    }
  };

  const beforeSubmit = (email: string, password: string) => {
    let validMail = false;
    let validPassword = false;

    if (!email) {
      setEmailError(LOGIN_ERRORS.incorrectEmailFormat);
    }

    if (!password) {
      setPasswordError(LOGIN_ERRORS.emptyFields);
    } else {
      setPasswordError(LOGIN_ERRORS.noError);
      validPassword = true;
    }

    if (!email.match(EmailRegex)) {
      setEmailError(LOGIN_ERRORS.incorrectEmailFormat);
    } else {
      setEmailError(LOGIN_ERRORS.noError);
      validMail = true;
    }

    return validMail && validPassword;
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const { email, password } = (
      event.target as typeof event.target & { elements: { email: { value: string }; password: { value: string } } }
    ).elements;

    const valid = beforeSubmit(email.value, password.value);

    if (valid) {
      startLogin(email.value, password.value);
    }
  };

  return (
    <>
      <div className={styles.root}>
        <Image alt="logo" src="/assets/logoVertical-128.png" />

        <form onSubmit={onSubmit} className={styles.form}>
          {showSpinner && (
            <div className={styles.spinner}>
              <Spinner label="Loading..." size={SpinnerSize.large} />
            </div>
          )}
          <Text className={styles.title}>Log in</Text>

          <Text className={styles.message} variant="large">
            Please log in to your Sparta account in order to continue
          </Text>

          <div className={styles.inputs}>
            <TextField
              disabled={isPopUpShowed}
              styles={{
                field: styles.input,
              }}
              onChange={() => setEmailError(LOGIN_ERRORS.noError)}
              errorMessage={emailError}
              name="email"
              placeholder="Sparta user email"
            />
            <TextField
              disabled={isPopUpShowed}
              styles={{
                field: styles.input,
              }}
              onChange={() => setPasswordError(LOGIN_ERRORS.noError)}
              errorMessage={passwordError}
              name="password"
              type="password"
              canRevealPassword={!isPopUpShowed}
              revealPasswordAriaLabel="Show password"
              placeholder="Password"
            />
          </div>

          <PrimaryButton disabled={isPopUpShowed} onClick={trackLoginButtonClicked} type="submit">
            Sign In
          </PrimaryButton>

          <div />

          <Link
            className={styles.terms}
            href="https://spartacommodities.com/excel-add-in-terms-and-conditions"
            target="_blank"
          >
            Terms & Conditions
          </Link>
        </form>
      </div>
      <Dialog hidden={hidden} closeFn={closeFn} description={description} title={title} primaryText="Ok" />
    </>
  );
};
