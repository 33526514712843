import { updateRibbonByUser } from "auth/ribbonButtons";
import { applyConditionalFormatToAllSheets, requestPrices } from "commands/commands";
import { differenceInSeconds } from "date-fns";
import { enableUpdateButton } from "ribbon/utils";
import { futures, livePrices } from "services";
import { UserMe } from "services/auth/types";
import { Provider } from "services/liveCurves/types";
import { Frequency } from "types";
import { getFrequencyValue } from "utils/helpers";
import { isEntitlementEnabled } from "utils/license";
import { getSelectedFrequency, setSelectedFrequency } from "utils/localStorage";

import { Subject } from "./Subject";
import { Metadata } from "./types";

export const LATEST_VALUES_KEY = "LATEST_VALUES";
export const METADATAS_KEY = "METADATAS";
export const LAST_PATH_KEY = "LAST_PATH";

export class SpartaAPI {
  private _socket: WebSocket[] | undefined = undefined;
  private _lastSocketMessage: Date | undefined = undefined;

  private _subscriptions = new Map<string, Subject<number | string | null>>();

  private _queueProducts = new Set<string>();
  private timeout: NodeJS.Timeout | undefined = undefined;

  private interval: NodeJS.Timer | undefined;

  private _user: UserMe | undefined;
  revalidateUser: boolean;

  activeRoute = new Subject(localStorage.getItem(LAST_PATH_KEY) || "");
  isTaskPaneVisible = false;

  private _updates = new Map<string, number | string | null>();

  constructor(user: UserMe | undefined) {
    this.revalidateUser = !!user;
    this.initialize(user);
  }

  private async initialize(user: UserMe | undefined) {
    await this.setUser(user);

    this.loadLatestValues();
    this.initConditionalFormats();
  }

  private static get _productsMetadata(): Record<string, Metadata> {
    try {
      const rawData = window.localStorage.getItem(METADATAS_KEY);
      if (rawData) {
        return JSON.parse(rawData);
      }

      return {};
    } catch (error) {
      return {};
    }
  }

  public get user() {
    return this._user;
  }

  public get isObEnabled() {
    return !!this.user?.launchDarklyFF?.some((ff) => ff.name === "fe_excel_ob" && ff.value);
  }

  public setUser(user: UserMe | undefined) {
    this._user = user;

    this.updateSocketConnection();
    this.startUpdateInterval();
    return updateRibbonByUser(this._user, this.updateFrequency);
  }

  public busForProduct(product: string, period: string, price?: number | string | null) {
    return this.busForKey(`${product}::${period}`, price, price === undefined);
  }

  private busForKey(key: string, price: number | string | null | undefined, revalidate: boolean) {
    let bus = this._subscriptions.get(key);
    let alreadyCreated = true;

    if (!bus) {
      alreadyCreated = false;
      bus = new Subject(price ?? null);

      this._subscriptions.set(key, bus);
    } else if (price !== undefined) {
      bus.next(price);
    }

    if (revalidate && !alreadyCreated) {
      const [product] = key.split("::");

      this.addToQueue(product);
    }

    return bus;
  }

  public isObserved(key: string) {
    const bus = this._subscriptions.get(key);

    if (bus) {
      return bus.observed;
    }

    return false;
  }

  public static setProductMetadatas(metadatas: Record<string, Metadata>) {
    const prevMetadatas = SpartaAPI._productsMetadata;

    const newMetadatas: Record<string, Metadata> = { ...prevMetadatas, ...metadatas };

    window.localStorage.setItem(METADATAS_KEY, JSON.stringify(newMetadatas));
  }

  public static getProductMetadata(productCode: string): Metadata | null {
    const obj = SpartaAPI._productsMetadata[productCode];

    if (!obj) return null;

    return obj;
  }

  public static computeProductLabel(name: Metadata["name"], units: Metadata["units"]) {
    return `${name} ${units.length ? units : ""}`.trim();
  }

  public static retrieveHistoricalLabels(metadata: Metadata): string[] {
    const { name, units, alias } = metadata;

    const names = Array.isArray(alias) && alias.length > 0 ? Array.from(new Set([name, ...alias])) : [name];

    return names.map((n) => SpartaAPI.computeProductLabel(n, units));
  }

  public static getProductsByLabel(label: string): Record<string, Metadata> {
    return Object.entries(SpartaAPI._productsMetadata).reduce((res, [code, metadata]) => {
      const labels = SpartaAPI.retrieveHistoricalLabels(metadata);

      if (!labels.includes(label)) return res;

      return {
        ...res,
        [code]: metadata,
      };
    }, {});
  }

  public static getProductCodeByLabelAndProvider(
    label: string,
    provider: Provider,
    isObEnabled = true
  ): string | undefined {
    const metadata = SpartaAPI._productsMetadata;

    for (const productCode in metadata) {
      const labels = SpartaAPI.retrieveHistoricalLabels(metadata[productCode]);
      const { dataProvider } = metadata[productCode];

      if (labels.includes(label) && (dataProvider === provider || !isObEnabled)) {
        return productCode;
      }
    }

    return undefined;
  }

  public static getLabelByProductCode(productCode: string) {
    const metadata = SpartaAPI.getProductMetadata(productCode);

    if (!metadata) return "";

    return SpartaAPI.computeProductLabel(metadata.name, metadata.units);
  }

  public isConnected() {
    return !!this._socket?.every((s) => s.readyState === WebSocket.OPEN);
  }

  private loadLatestValues() {
    try {
      const rawData = window.localStorage.getItem(LATEST_VALUES_KEY);

      // Legacy to support old documents that use document.settings instead of localstorage
      let data: Record<string, number | null> = Office.context.document.settings.get(LATEST_VALUES_KEY) || {};

      if (rawData) {
        const localStorageData = JSON.parse(rawData);
        data = { ...data, ...localStorageData };
      }

      Object.entries(data).forEach(([key, value]) => this.busForKey(key, value, true));
    } catch (error) {
      return null;
    }
  }

  public getObservedProducts() {
    return [
      ...new Set(
        [...this._subscriptions.entries()].flatMap(([key, subscription]) =>
          subscription.observed ? key.split("::")[0] : []
        )
      ),
    ].map(SpartaAPI.getLabelByProductCode);
  }

  public saveLatestValues() {
    try {
      const values = Array.from(this._subscriptions.entries())
        .filter(([, subscription]) => subscription.observed)
        .reduce<Record<string, number | string | null>>(
          (res, [key, subscription]) => ({
            ...res,
            [key]: subscription.getValue(),
          }),
          {}
        );

      window.localStorage.setItem(LATEST_VALUES_KEY, JSON.stringify(values));
    } catch (error) {
      console.error(error);
    }
  }

  public userNavigate(path: string) {
    this.activeRoute.next(path);

    if (path !== "redirect") {
      localStorage.setItem(LAST_PATH_KEY, path);
      return this.openTaskPane();
    }
  }

  public openTaskPane() {
    if (!this.isTaskPaneVisible) {
      this.isTaskPaneVisible = true;
      return Office.addin.showAsTaskpane();
    }
  }

  public navigateToLastPath() {
    const lastPath = localStorage.getItem(LAST_PATH_KEY);

    if (lastPath) {
      this.activeRoute.next(lastPath);
    }
  }

  public updateSocketConnection() {
    if (
      this._user &&
      this.getSocketStatus() === "disconnected" &&
      (this.updateFrequency !== "pause" || !Object.entries(SpartaAPI._productsMetadata).length)
    ) {
      this._socket?.forEach((s) => s.close());
      if (isEntitlementEnabled(this._user, "iceData")) {
        this._socket = [livePrices(), futures()];
      } else {
        this._socket = [livePrices()];
      }
    } else if (!this._user || this.updateFrequency === "pause") {
      this._socket?.forEach((s) => s.close());
      this._socket = undefined;
    }
  }

  public socketReceived() {
    this._lastSocketMessage = new Date();
  }

  public getSocketStatus() {
    const statuses = this._socket?.map((s) => s.readyState);

    if (
      statuses?.every((s) => s === 1) &&
      (!navigator.onLine || !this._lastSocketMessage || differenceInSeconds(new Date(), this._lastSocketMessage) > 30)
    ) {
      return "connecting";
    }

    if (statuses?.some((s) => s === 0)) return "connecting";
    if (statuses?.every((s) => s === 1)) return "connected";
    return "disconnected";
  }

  public initConditionalFormats() {
    return applyConditionalFormatToAllSheets();
  }

  private addToQueue(product: string) {
    this._queueProducts.add(product);
    this.timeoutProducts();
  }

  private timeoutProducts() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      if (this._user && this._queueProducts.size && this.updateFrequency !== "pause") {
        const products = [...this._queueProducts];
        requestPrices(products.map((code) => ({ code, type: SpartaAPI.getProductMetadata(code)?.type || "Normal" })));
        this._queueProducts.clear();
      } else {
        this.timeoutProducts();
      }
    }, 5000);
  }

  public registerUpdate(product: string, period: string, price: number | string | null) {
    this.registerUpdateByKey(`${product}::${period}`, price);
  }

  public registerUpdateByKey(key: string, value: number | string | null) {
    if (this.updateFrequency === "live") {
      this.busForKey(key, value, false);
    } else {
      this._updates.set(key, value);
    }
  }

  public set updateFrequency(frequency: Frequency) {
    if (frequency !== this.updateFrequency) {
      const previous = this.updateFrequency;
      setSelectedFrequency(frequency);

      this.updateSocketConnection();
      this.startUpdateInterval();

      updateRibbonByUser(this._user, frequency, previous !== "pause");

      if (previous === "pause") {
        this.revalidatePrices();
      }
    }
  }

  public get updateFrequency() {
    return getSelectedFrequency();
  }

  private startUpdateInterval() {
    clearInterval(this.interval);

    if (this.updateFrequency === "idle" || this.updateFrequency === "recommended") {
      const updateFn = () => {
        Array.from(this._updates.entries()).forEach(([key, value]) => this.busForKey(key, value, false));
      };

      this.interval = setInterval(updateFn, getFrequencyValue(this.updateFrequency, this.user));

      if (this.updateFrequency === "recommended") updateFn();
    }
  }

  private async revalidatePrices() {
    const products = Array.from(this._subscriptions.entries()).flatMap(([key, subscription]) =>
      subscription.observed ? key.split("::")[0] : []
    );

    if (products.length) {
      await requestPrices(
        products.map((code) => ({ code, type: SpartaAPI.getProductMetadata(code)?.type || "Normal" }))
      );
    }

    enableUpdateButton();
  }

  // #region Debug functions
  public dumpUser(): string {
    return JSON.stringify(this._user);
  }

  public dumpProducts(): string {
    return JSON.stringify({
      count: Object.entries(SpartaAPI._productsMetadata).length,
      ...SpartaAPI._productsMetadata,
    });
  }

  public dumpSubscriptions(): string {
    return JSON.stringify({
      count: this._subscriptions.size,
      headValues: Array.from(this._subscriptions.keys()).slice(0, 150),
    });
  }

  public dumpQueue(): string {
    return JSON.stringify({ count: this._queueProducts.size, ...Object.fromEntries(this._queueProducts.entries()) });
  }
  // #endregion
}
