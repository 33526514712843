import { createRoot } from "react-dom/client";
import { MemoryRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { ThemeProvider } from "@fluentui/react";
import { recalculateWorkbook } from "commands/commands";
import SegmentWrapper from "segment/SegmentWrapper";
import { trackTaskPaneClosed } from "segment/taskpane";
import datadogRumInit from "utils/datadogRumInit";

import App from "./App";
import { TaskPaneProvider } from "./context";

import "./styles/index.scss";

initializeIcons();

const render = () => {
  const rootElement = document.getElementById("root");

  if (rootElement) {
    const root = createRoot(rootElement);

    root.render(
      <ThemeProvider>
        <TaskPaneProvider>
          <MemoryRouter>
            <SegmentWrapper>
              <App />
            </SegmentWrapper>
          </MemoryRouter>
        </TaskPaneProvider>
      </ThemeProvider>
    );
  }
};

let isDatadogInitialized = false;

/* Render application after Office initializes */
Office.onReady(() => {
  recalculateWorkbook();
  Office.addin.onVisibilityModeChanged(function (args) {
    console.log("visibilityMode", args.visibilityMode);
    global.Sparta.isTaskPaneVisible = args.visibilityMode === Office.VisibilityMode.taskpane;
    if (args.visibilityMode === Office.VisibilityMode.hidden) {
      global.Sparta.userNavigate("redirect");
      trackTaskPaneClosed();
    }
  });
  Office.addin.setStartupBehavior(Office.StartupBehavior.load);
  render();

  if (!isDatadogInitialized) {
    datadogRumInit();
    isDatadogInitialized = true;
  }
});
