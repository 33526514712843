import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Spinner, SpinnerSize } from "@fluentui/react";
import useTaskPaneContext from "taskpane/context";
import { isWelcomeAccepted } from "utils/localStorage";

import styles from "./RoutesManager.module.scss";

type Props = {
  authenticationRequired?: boolean;
};

const getPublicPath = () => (isWelcomeAccepted() ? "/login" : "/welcome");

export const RoutesManager: FC<Props> = ({ authenticationRequired = false }) => {
  const { isLogged, user } = useTaskPaneContext();

  const isAllowed = authenticationRequired ? isLogged : !isLogged;
  const redirectPath = authenticationRequired ? getPublicPath() : "/";

  if (isAllowed && authenticationRequired && !user)
    return (
      <div className={styles.loader}>
        <Spinner size={SpinnerSize.large} />
      </div>
    );

  return isAllowed ? <Outlet /> : <Navigate to={redirectPath} replace />;
};
