import { useEffect, useMemo, useState } from "react";
import { useSetInterval } from "@fluentui/react-hooks";
import { resetUser } from "segment";
import { AuthenticateResponse } from "services/auth/types";
import { clearLocalStorage } from "utils/localStorage";
import { getToken } from "utils/token";

import { TaskPaneContext } from "./TaskPaneContext";

export const TaskPaneProvider: FCC = ({ children }) => {
  const { setInterval } = useSetInterval();
  const [isLogged, setIsLogged] = useState(!!getToken());
  const [shouldBeWelcome, setShouldBeWelcome] = useState(false);
  const [user, setUser] = useState(global.Sparta?.user);

  setInterval(() => {
    setUser(global.Sparta?.user);
  }, 1000);

  useEffect(() => {
    setIsLogged(!!user && !!getToken());
  }, [user]);

  const loginFromDialog = (response: AuthenticateResponse | undefined = undefined) => {
    setShouldBeWelcome(true);
    login(response);
  };

  const login = async (response: AuthenticateResponse | undefined = undefined) => {
    try {
      if (response) {
        Object.entries(response).forEach(([k, v]) => localStorage.setItem(k, v.toString()));
        setIsLogged(true);
      }
    } catch (error) {
      setIsLogged(false);
      console.error(error);
    }
  };

  const logout = () => {
    clearLocalStorage();
    setIsLogged(false);
    setUser(undefined);
    resetUser();
  };

  const value = useMemo(
    () => ({ isLogged, login, loginFromDialog, logout, shouldBeWelcome, user }),
    [isLogged, login, loginFromDialog, logout, shouldBeWelcome, user]
  );

  return <TaskPaneContext.Provider value={value}>{children}</TaskPaneContext.Provider>;
};
