import React, { useState } from "react";
import { Checkbox, Link, MessageBarType, Spinner, SpinnerSize, Text } from "@fluentui/react";
import { Footer, Logout, MessageBar, PrimaryButton, SearchInput } from "taskpane/components";
import { Checkbox as CheckboxType } from "types";

import { UpdateFrequencyMessage } from "../../components/UpdateFrequencyMessage";
import { trackInfoProductBlocked } from "../Products/segment";
import {
  trackDownloadPortfolio,
  trackErrorAddPortfolioScreen,
  trackErrorDownloadingPortfolio,
  trackRetryErrorAddPortfolioScreen,
} from "./segment";
import usePortfoliosForm from "./usePortfoliosForm";
import { ExceptionPortfolioErrorType } from "./utils";

import styles from "./Portfolios.module.scss";

export const Portfolios = () => {
  const [filter, setFilter] = useState("");
  const [showUpdateFrequencyInfo, setShowUpdateFrequencyInfo] = useState(false);

  const { cleanError, error, filterError, onClickAdd, onClickCheckbox, selected, isDownloading, portfolios } =
    usePortfoliosForm(filter);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Text className={styles.title}>Add Portfolio</Text>

        {showUpdateFrequencyInfo && <UpdateFrequencyMessage onDismiss={() => setShowUpdateFrequencyInfo(false)} />}

        {error && error.type === "EXCEL" && (
          <MessageBar
            onDismiss={cleanError}
            track={() =>
              trackErrorDownloadingPortfolio(
                (error as ExceptionPortfolioErrorType<CheckboxType[]>)?.portfolios.map((p) => p.name)
              )
            }
            type={MessageBarType.error}
          >
            {error.toString()}
          </MessageBar>
        )}

        {error && error.type === "NETWORK" && (
          <MessageBar
            actions={
              portfolios.length === 0 ? (
                <Link
                  className={styles.retry}
                  onClick={() => {
                    trackRetryErrorAddPortfolioScreen();
                    window.location.reload();
                  }}
                >
                  Retry
                </Link>
              ) : undefined
            }
            onDismiss={cleanError}
            track={trackErrorAddPortfolioScreen}
            type={MessageBarType.error}
          >
            {error.toString()}
          </MessageBar>
        )}

        {error && error.type === "PRODUCT_BLOCKED" && (
          <MessageBar
            onDismiss={cleanError}
            track={() => trackInfoProductBlocked((error as ExceptionPortfolioErrorType<string[]>)?.portfolios)}
            type={MessageBarType.error}
          >
            {error.message}
          </MessageBar>
        )}
      </div>

      <div className={styles.container}>
        {!portfolios?.length && (
          <div className={styles.spinner}>
            <Spinner label="Retrieving Data..." size={SpinnerSize.large} />
          </div>
        )}
        {isDownloading && (
          <div className={styles.spinner}>
            <Spinner label="Downloading Data..." size={SpinnerSize.large} />
          </div>
        )}

        <SearchInput value={filter} error={filterError} onChange={setFilter} />

        <ul className={styles.content}>
          {portfolios.map(({ id, name }) => (
            <li key={id}>
              <Checkbox
                checked={selected.some(({ id: selectedId }) => selectedId === id)}
                onChange={() => onClickCheckbox(id, name)}
                styles={{ text: styles.text }}
                label={name}
              />
            </li>
          ))}
        </ul>

        <Footer>
          <Logout page="Add Portfolio" />
          <PrimaryButton
            disabled={selected.length === 0}
            onClick={() => {
              trackDownloadPortfolio(selected.map((s) => s.name));
              setShowUpdateFrequencyInfo(true);
              onClickAdd();
            }}
          >
            Add
          </PrimaryButton>
        </Footer>
      </div>
    </div>
  );
};
