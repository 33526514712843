import { track } from "segment";

/**
 * When the user clicks on the ‘Add’ button after having selected one or more
 * portfolios.
 * @param {string[]} portfolios - Name of the portfolios downloaded.
 */
export const trackDownloadPortfolio = (portfolios: string[]): void => {
  track("Download Portfolio", { portfolios });
};

/**
 * When the error message is shown on the ‘Add Portfolio’ screen to notify the
 * user about an error while downloading a portfolio.
 * @param {string[]} portfolios - portfolios: Name of the portfolios that failed.
 */
export const trackErrorDownloadingPortfolio = (portfolios: string[]): void => {
  track("Error Downloading Portfolio", { portfolios });
};

/**
 * When the error message is shown on the ‘Add Portfolio’ screen to notify the user about an error loading the screen.
 */
export const trackErrorAddPortfolioScreen = (): void => {
  track("Error Add Portfolio Screen");
};

/**
 * When the user clicks on the Retry button after having an error loading the ‘Add Portfolio’ screen.
 */
export const trackRetryErrorAddPortfolioScreen = (): void => {
  track("Retry Error Add Portfolio Screen");
};
